import "./App.css";

function App() {
  return (
    <div className="App">
      <iframe
        title="dashboard"
        src="https://coda.io/embed/BgTXqhkYiO/_su4To?viewMode=embedplay&hideSections=true"
      ></iframe>
    </div>
  );
}

export default App;
